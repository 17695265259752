<template>
  <div v-if="active">
    <b-modal id="edit-phone-modal" hide-footer hide-header>
      <div class="d-block">
        <b-container>
          <validation-observer v-slot="{ invalid }">
            <validation-provider
              v-slot="{ errors }"
              rules="required|phone_number"
              immediate
              name="Phone Number"
            >
              <b-row>
                <b-col>
                  <form ref="form" @submit.prevent="editInfo">
                    <b-form-group label="Phone Number">
                      <b-form-input
                        v-model="editInfo.phone_number"
                        :state="!errors[0]"
                      />
                      <span>{{ errors[0] }}</span>
                    </b-form-group>
                  </form>
                </b-col>
              </b-row>
              <b-button
                variant="primary"
                type="submit"
                :disabled="invalid"
                @click="update(editInfo)"
              >
                Save
              </b-button>
            </validation-provider>
          </validation-observer>
        </b-container>
      </div>
    </b-modal>

    <b-modal id="add-phone-modal" hide-footer hide-header>
      <validation-observer v-slot="{ invalid }">
        <div class="d-block">
          <b-container>
            <validation-provider
              v-slot="{ errors }"
              rules="required|phone_number"
              immediate
              name="Phone Number"
            >
              <b-row>
                <b-col>
                  <form ref="form" @submit.prevent="newPhone">
                    <b-form-group label="Phone Number">
                      <b-form-input
                        v-model="newPhone.phone_number"
                        type="number"
                        :state="!errors[0]"
                      />
                      <span>{{ errors[0] }}</span>
                    </b-form-group>
                  </form>
                </b-col>
              </b-row>
              <b-button
                variant="primary"
                type="submit"
                :disabled="invalid"
                @click="create"
              >
                Add Phone
              </b-button>
            </validation-provider>
          </b-container>
        </div>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PhoneModal',
  components: { ValidationObserver, ValidationProvider },
  props: ['bus'],
  async mounted() {
    this.bus.$on('phone', this.open)
  },
  computed: {
    ...mapGetters('account', ['account']),
  },
  methods: {
    ...mapActions('account', ['updatePersonPhone', 'createPersonPhone']),
    async create() {
      const params = { ...this.newPhone, ...{ person_id: this.account.id } }
      try {
        await this.createPersonPhone(params)
        this.$bvModal.hide('add-phone-modal')
        this.$emit('complete')
        this.active = false
      } catch(error) {
        this.errorToast(error.response.data.error.message)
      }
    },
    async update(phone) {
      try {
        await this.updatePersonPhone(phone)
        this.$bvModal.hide('edit-phone-modal')
        this.$emit('complete')
        this.active = false
      } catch(error) {
        this.errorToast(error.response.data.error.message)
      }
    },
    errorToast(error) {
      this.$bvToast.toast(error, { title: 'Error', variant: 'danger' })
    },
    open(phone, name) {
      if (name === 'edit') {
        this.editInfo = phone
      }
      this.active = true
    },
  },
  data() {
    return {
      active: false,
      phone: null,
      name: null,
      editInfo: null,
      newPhone: {},
      errors: [],
    }
  },
}
</script>
